.footer{
    position: relative;
    color: #355628;
    width: 100%;
    bottom: 0;
    padding: 10px 0px;
    background: #ffffff;
}

.footersignup{
    position: relative;
    width: 30%!important;
    padding: 0;
}

@media only screen and (max-width: 700px) {

    .footersignup{
        width: 100%!important;
    }

}