@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bg-light {
  background-color: #fff!important;
}

.mb-4 {
  margin-bottom: 0 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.navbar-light .navbar-brand {
  color: #929292;
}

.page {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
}

/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 2000ms linear 0ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 0ms linear;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  width: 100%;
  height: 100%;
}

.homeChanges{
  position: relative;
  padding: 50px;
  width:45%;
  float: left;
}

.text-uppercase{
  text-Transform: "uppercase";
}
.bg-light {
    background-color: #fff!important;
}

a {
    color:#1b380f!important;
    text-decoration: none;
    background-color: transparent;
}

.btn.btn-primary a {
    color: #fff!important;
}


/* Style inputs with type="text", select elements and textareas */
input[type=text], select, textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical /* Allow the user to vertically resize the textarea (not horizontally) */
  }
  
  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* When moving the mouse over the submit button, add a darker green color */
  input[type=submit]:hover {
    background-color: #45a049;
  }
  
  /* Add a background color and some padding around the form
  .container {
    border-radius: 5px;
    background-color: ;
    padding: 20px;
  } */

/* DEMO STYLES */
/* .nav {
    padding: 30px 0;
    background-color: #222;
  }
  .nav a {
    display: inline-block;
    width: 100px;
    text-align: center;
    margin: 0 15px;
    padding: 10px;
    border: 0px rgba(255,255,255,0.5) solid;
    transition: all 0.3s linear;
    text-decoration: none;
    color: white;
  }
  .nav a.active {
    background-color: #61DAFB;
    color: #222;
  } */
  
section {
    padding: 50px 0;
    max-width: 600px;
    margin: 0 auto;
}

#feature {
    background: #f2f2f2;
    padding-bottom: 40px;
}

.feature-wrap {
    padding-bottom: 55px;
}

.feature-wrap h2 {
    margin-top: 10px;
    font-size: 20px;
}

.feature-wrap h3 {
    font-size: 16px;
    color: #787878;
    font-weight: 400;
    line-height: 24px;
}

.feature-wrap i {
    font-size: 48px;
    height: 110px;
    width: 110px;
    margin: 3px;
    background: #fff;
    border-radius: 100%;
    line-height: 110px;
    text-align: center;
    border: 3px solid #fff;
    box-shadow: inset 0 0 0 5px #f2f2f2;
    color: #e8ce0e;
    float: left;
    margin-right: 25px;
}

label[for] {
    cursor: pointer;
}

input[type="checkbox"] {
    display: none;
    margin-right: 20px;
}

.lightbox {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    z-index: 1;
    overflow: auto;
    transform: scale(0);
    transition: transform .75s ease-out;
}

.lightbox img {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 96%;
    max-height: 96%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}

input[type="checkbox"]:checked+.lightbox {
    transform: scale(1);
}

input[type="checkbox"]:checked~.grid {
    opacity: .125;
}

.grid {
    width: 100%;
    /* position: fixed; */
    top: 0;
    left: 0;
    height: 100%;
    /* background-color: #222; */
    z-index: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0px;
    overflow: auto;
    text-align: center;
    transition: opacity .75s;
}

.grid .grid-item {
    width: 50%;
    display: inline-block;
    padding: 16px;
    opacity: 1;
    transition: opacity .5s;
}

.service-item{
    background-color:#fff;
    padding: 10px;
    color: green;
    border: 1px solid green;
    box-shadow: 5px 10px #466d37;
    margin: 0.5em 0;    
}

.img-blog {
    border-radius: 5px;
    padding-bottom: 20px;
    padding-right: 20px;
}


/* .grid .grid-item:hover { opacity: 1; }
@media screen and (max-width: 1024px) {
 
.grid .grid-item { width: 50%; }
}
@media screen and (max-width: 480px) {
 
.grid .grid-item { width: 100%; }
} */

.grid img {
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
}


/******************* Accordion Demo - 3 *****************/

#accordion3 {
    padding-left: 80px;
    overflow: hidden;
    position: relative;
    /* z-index: 1; */
}

#accordion3:before {
    content: "";
    width: 5px;
    height: 100%;
    background: #004e89;
    position: absolute;
    top: 0;
    left: 22px;
    z-index: -1;
}

#accordion3 .panel-title a {
    display: block;
    padding: 10px 30px 15px 0;
    background: #fff;
    font-size: 18px;
    font-weight: 500;
    color: #004e89;
    position: relative;
    transition: all 0.5s ease 0s;
    box-shadow: 0 1px 2px rgba(43, 59, 93, 0.30);
}

#accordion3 .panel-title a:before {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    width: 50px;
    height: 50px;
    line-height: 40px;
    border-radius: 50%;
    background: #cad5c2;
    text-align: center;
    font-size: 17px;
    color: #004e89;
    border: 6px solid #004e89;
    position: absolute;
    top: 0;
    left: -80px;
}

#accordion3 .panel-title a.collapsed:before {
    content: "\f067";
    background: #fff;
}

#accordion3 .panel-body {
    padding: 0px 15px;
    background: #eee;
    border: none;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5) inset, 0 1px 2px rgba(255, 255, 255, 0.9);
    font-size: 14px;
    color: #487677;
    line-height: 25px;
}

.spacer {
    min-height: 100px;
}

#services {
    background: #000;
    background-size: cover;
}

#services h2 {
    color: #fff;
}

#services .lead {
    color: #fff;
}

.service-wrap {
    background: #fff;
    padding: 30px;
    border-radius: 6px;
    margin-bottom: 30px;
}

.service-wrap .media-heading {
    font-size: 20px;
}

.blog-content {
    padding-bottom: 50px;
}

.blog-content h3 {
    font-size: 25px;
}

.img-blog {
    border-radius: 5px;
    padding-bottom: 20px;
}

.contact-form {
    text-align: center;
    padding-bottom: 25px;
}

p {
    margin-top: 0;
    margin-bottom: 8px;
}

.btn.btn-primary a {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}

.btn.btn-primary a:hover {
    color: #ccc;
    text-decoration: none;
    background-color: transparent;
}

.truncate {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


.carousel-caption {
    bottom: 50%!important;
}

.slidefont {
    font-size: 40px;
    color: white;
  text-shadow: 10px 10px 10px #000000;

}

.socialicons{
    margin: 2px;
    padding: 2px;
}

.h1, h1 {
  padding: 4px 0;
  font-size: 1.7rem!important;
}
.h2, h2 {
  padding: 4px 0;
  font-size: 1.25rem!important;
}
.h3, h3 {
  padding: 4px 0;
  font-size: 1.23rem!important;
}

@media only screen and (max-width: 600px) {
    .carousel-caption {
        bottom: 20%!important;
    }
    .carousel-indicators {
      bottom: -20px!important;
  }
    .slidefont {
        font-size: 20px!important;
        text-shadow: 2px 2px 10px #000;
    
    }
    .h1, h1 {
      padding: 4px 0;
      font-size: 1.2rem!important;
    }
    .h2, h2 {
      padding: 4px 0;
      font-size: 1.25rem!important;
    }
    .h3, h3 {
      padding: 4px 0;
      font-size: 1.23rem!important;
    }
    
  }




  /* MENU STYLES */
.menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .menu-wrap .toggler {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 50px;
    height: 50px;
    opacity: 0;
  }
  
  .menu-wrap .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 60px;
    height: 60px;
    padding: 1rem;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Hamburger Line */
  .menu-wrap .hamburger > div {
    position: relative;
    flex: none;
    width: 100%;
    height: 2px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease;
  }
  
  /* Hamburger Lines - Top & Bottom */
  .menu-wrap .hamburger > div::before,
  .menu-wrap .hamburger > div::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -10px;
    width: 100%;
    height: 2px;
    background: inherit;
  }
  
  /* Moves Line Down */
  .menu-wrap .hamburger > div::after {
    top: 10px;
  }
  
  /* Toggler Animation */
  .menu-wrap .toggler:checked + .hamburger > div {
    transform: rotate(135deg);
  }
  
  /* Turns Lines Into X */
  .menu-wrap .toggler:checked + .hamburger > div:before,
  .menu-wrap .toggler:checked + .hamburger > div:after {
    top: 0;
    transform: rotate(90deg);
  }
  
  /* Rotate On Hover When Checked */
  .menu-wrap .toggler:checked:hover + .hamburger > div {
    transform: rotate(225deg);
  }
  
  /* Hamburger Show Menu */
  .menu-wrap .toggler:checked ~ .menu {
    visibility: visible;
  }
  
  .menu-wrap .toggler:checked ~ .menu > div {
    transform: scale(1);
    transition-duration: var(--menu-speed);
  }
  
  .menu-wrap .toggler:checked ~ .menu > div > div {
    opacity: 1;
    transition:  opacity 0.4s ease 0.4s;
  }
  
  .menu-wrap .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .menu-wrap .menu > div {
    background: var(--overlay-color);
    border-radius: 50%;
    width: 200vw;
    height: 200vw;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: all 0.4s ease;
  }
  
  .menu-wrap .menu > div > div {
    text-align: center;
    max-width: 90vw;
    max-height: 100vh;
    opacity: 0;
    transition: opacity 0.4s ease;
  }
  
  .menu-wrap .menu > div > div > ul > li {
    list-style: none;
    color: #fff;
    font-size: 1.5rem;
    padding: 1rem;
  }
  
  .menu-wrap .menu > div > div > ul > li > a {
    color: inherit;
    text-decoration: none;
    transition: color 0.4s ease;
  }

  
  /* Modal Background Image */
  .modal-background{
    background: #000 url(/static/media/popup-modal-bg.ed910d9a.jpg);
    color: #fff;
    text-align: left;
}

/* HomePage Online Store Box */
.online-store-box{
  background: #466d37;
    color: #fff;
    text-align: center;
    padding: 20px;
    margin-top: 20px;
    font-size: 30px;
    font-weight: 100;
}

/* HomePage Landscape Design Box */
.landscapedesign-box{
  background: #27471a;
    color: #fff;
    text-align: center;
    padding: 20px;
    margin-top: 20px;
    font-size: 30px;
    font-weight: 100;
}


@media only screen and (max-width: 600px) {
  .online-store-box{
      font-size: 18px;
  }

  .landscapedesign-box{
    font-size: 18px;
  }
}
.footer{
    position: relative;
    color: #355628;
    width: 100%;
    bottom: 0;
    padding: 10px 0px;
    background: #ffffff;
}

.footersignup{
    position: relative;
    width: 30%!important;
    padding: 0;
}

@media only screen and (max-width: 700px) {

    .footersignup{
        width: 100%!important;
    }

}
